<template>
  <div
    class=""
    v-if="site.icon && page.length"
  >
    <swiper
      :autoplay="true"
      loop
      pagination
      :navigation="!isMobile"
      @swiper="onSlideChange"
    >
      <swiper-slide
        v-for="item in page[0].slide"
        :key="item.t1"
        class="relative"

      >
        <div class="absolute-center c-000 tc"
             :style="isMobile ? 'width: 80%;' : ''"
        >
          <div
            class="b"
            :class="isMobile ? 'f28' : 'f40'"
            :style="isMobile ? 'letter-spacing: 4px; margin-bottom: 8px;' : 'letter-spacing: 12px; margin-bottom: 15px;'"
            data-aos="zoom-in"
            data-aos-delay="300"
            v-vue-aos.once="{animationClass:'fadeInUp animated'}"
            v-html="item.t1"
          >
<!--            {{ item.t1 }}-->
          </div>
          <div
            :style="isMobile ? 'letter-spacing: 2px; margin-bottom: 8px;' : 'letter-spacing: 12px; color:#444;'"
            data-aos="zoom-in"
            data-aos-delay="500"
            :class="isMobile ? 'f20 nowrap' : 'f32'"
            v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          >
            {{ item.t2 }}
          </div>
        </div>
        <img
          :src="isMobile ? item.img_m : item.img"
          class="width-100"
          alt=""
        >
      </swiper-slide>
    </swiper>

    <div>
      <div class="relative">
        <img
          :src="isMobile ? page[1].img_m : page[1].img"
          alt=""
          class="width-100"
          :style="isMobile ? '' : 'height:850px;'"
        >
        <div
          class="absolute-center flex-center"
          :style="isMobile ? 'width:100%' : 'width:1280px'"
        >
          <div :style="isMobile ? 'text-align:center' : 'padding-right: 120px;'">
            <div
              :class="isMobile ? 'f28' : 'f40'"
              data-aos="fade-up"
              v-vue-aos.once="{animationClass:'fadeInUp animated'}"
            >
              {{ page[1].t1 }}
            </div>
            <div
              :class="isMobile ? 'f20 mt10 mb15' : 'f20 mt40 mb30'"
              style="font-weight:lighter"
              data-aos="fade-up"
              data-aos-delay="50"
              v-vue-aos.once="{animationClass:'fadeInUp animated'}"
            >
              {{ page[1].t2 }}
            </div>
<!--            <div-->
<!--              class="c-999 f14"-->
<!--              data-aos="fade-up"-->
<!--              data-aos-delay="100"-->
<!--              v-vue-aos.once="{animationClass:'fadeInUp animated'}"-->
<!--            >-->
<!--              {{ page[1].t3.join('，') }}-->
<!--            </div>-->
            <div
              class="bd bd-000 f14 plr10 ptb5 dib cp"
              :class="isMobile ? 'mt30' : 'mt20'"
              data-aos="fade-up"
              data-aos-delay="100"
              v-vue-aos.once="{animationClass:'fadeInUp animated'}"
            >
              <a href="/chanpin" class="c-333">
              探索更多 ➞
              </a>
            </div>
          </div>
          <div
            v-if="!isMobile"
            class="relative"
            data-aos="zoom-out-down"
            data-aos-delay="200"
            v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          >
            <img
              :src="page[1].img2"
              alt=""
              style="width:720px;"
            >
          </div>
        </div>
      </div>
      <div
        v-if="isMobile"
        class=""
      >
        <img
          :src="page[1].img2_m"
          alt=""
          class="width-100"
        >
      </div>
    </div>

    <div
      class="relative"
      style="background: #E6EAF0;z-index:11"
      :style="isMobile ? '' : {
        padding:'40px 0'
      }"
    >
      <div
        class="width-100"
        :class="isMobile ? 'flex-center absolute t0 l0 z1' : 'flex-center-between'"
        :style="isMobile ? '' : {
        height: '600px',
        backgroundImage:`url(${page[2].img})`,
        backgroundSize:'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }"
      >
        <div :style="isMobile ? 'text-align:center;padding-top:40px;' : 'padding-left: 300px;'">
          <div
            data-aos="fade-up"
            v-vue-aos.once="{animationClass:'fadeInUp animated'}"
            :class="isMobile ? 'f28' : 'f40'"
          >
            {{ page[2].t1 }}
          </div>
          <div
            class="f20"
            :class="isMobile ? 'mt20 mb10' : 'mt20 mb20'"
            style="font-weight:lighter"
            data-aos="fade-up"
            data-aos-delay="50"
            v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          >
            {{ page[2].t2 }}
          </div>
<!--          <div>-->
<!--            <div-->
<!--              class="c-fff bg-000 plr10 ptb10 f14 dib"-->
<!--              :style="isMobile ? 'padding:6px 10px' : ''"-->
<!--              data-aos="fade-up"-->
<!--              data-aos-delay="100"-->
<!--              v-vue-aos.once="{animationClass:'fadeInUp animated'}"-->
<!--            >-->
<!--              {{ page[2].t3 }}-->
<!--            </div>-->
<!--          </div>-->
          <div
            class="bd bd-000 f14 plr10 ptb5 dib cp"
            :class="isMobile ? 'mt40' : 'mt50'"
            data-aos="fade-up"
            data-aos-delay="100"
            v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          >
            <a href="/quanrenyuan" class="c-333">
              探索更多 ➞
            </a>
          </div>
        </div>
        <div
          v-if="!isMobile"
          class="relative"
        >
<!--          <img-->
<!--            :src="page[2].img"-->
<!--            alt=""-->
<!--            style="width:960px; "-->
<!--            data-aos="fade-left"-->
<!--            data-aos-delay="50"-->
<!--            v-vue-aos.once="{animationClass:'fadeInUp animated'}"-->
<!--          >-->
        </div>
      </div>
      <div
        v-if="isMobile"
      >
        <img
          :src="page[2].img_m"
          alt=""
          class="width-100"
        >
      </div>
    </div>

    <div
      v-if="isMobile"
      style="background: #F6F6F6"
    >
      <img
        :src="page[3].img"
        class="width-100"
        alt=""
      >
      <div
        class="mlr10 p20 pt30 bg-fff relative z1 pb50"
        style="margin-top:-100px"
      >
        <div
          class="f32 tc"
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        >
          {{ page[3].t1 }}
        </div>
        <div
          class="f16 mt30"
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        >
          {{ page[3].t2 }}
        </div>
        <div
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
          class="f16 mt20"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        >
          {{ page[3].t3 }}
        </div>
        <div
          class="bd bd-000 f14 plr10 ptb5 dib mt30 cp"
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        >
          <a href="/zoujin" class="c-333">
            探索更多 ➞
          </a>
        </div>
      </div>


    </div>
    <div
      v-else
      class="relative ptb50"
      style="background:#ADC6DA;height: 720px;"
    >
      <div
        class="relative z2"
        style="padding-left:300px;padding-top:50px;"
      >
        <div style="width:550px;">
          <div
            class="f40"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
            v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          >
            {{ page[3].t1 }}
          </div>
          <div
            class="f20 mt40 mb30"
            style="font-weight:lighter"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
            v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          >
            {{ page[3].t2 }}
          </div>
          <div
            class="f20 mt40 mb30"
            style="font-weight:lighter"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
            v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          >
            {{ page[3].t3 }}
          </div>
          <div
            class="bd bd-000 f14 plr10 ptb5 dib mt50 cp"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
            v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          >
            <a href="/zoujin" class="c-333">
              探索更多 ➞
            </a>
          </div>
        </div>
      </div>
      <img
        class="absolute b0 r0 width-100 z1"
        :src="page[3].img"
        alt=""
      >
    </div>

    <div
      class="tc pb50"
      style="background:#F6F6F6"
    >
      <div
        class="relative z1"
        :style="isMobile ? 'font-size:24px;padding: 30px 0 0px 0;' : 'font-size:40px;padding: 80px 0 10px 0;'"
        data-aos="fade-up"
        v-vue-aos.once="{animationClass:'fadeInUp animated'}"
      >
        {{ page[4].t1 }}
      </div>
      <div
        class="dib relative z1"
        style="width: 30px;height: 2px;background: #ed7989;"
      />
      <div
        style="font-weight: lighter;"
        :style="isMobile ? 'font-size:18px;padding:10px 0 20px' : 'font-size:30px;padding:20px 0 30px'"
        data-aos="fade-up"
        v-vue-aos.once="{animationClass:'fadeInUp animated'}"
      >
        {{ page[4].t2 }}
      </div>
      <div>
        <img
          :src="isMobile ? page[4].img_m : page[4].img"
          alt=""
          :style="isMobile ? 'width:100%' : 'width:900px;'"
        >
      </div>

      <div style="background: #fff; "
           :style="isMobile ? '' : 'width: fit-content;  margin: 0 auto;'"
      >
        <div
          class="flex-center bg-fff relative z1"
          :class="isMobile ? 'mlr10 p20' : 'm-auto p30'"
          :style="isMobile ? 'margin-top:-30px;' : 'width: fit-content; padding-top:50px;'"
        >
          <div
            v-for="(item, i) in page[4].data"
            :key="item.t"
            :style="isMobile ? 'height:120px' : 'width:370px;height:130px'"
            :class="{
            'db blr bd-eee': i === 1,
            'mlr10 plr10': i === 1 && isMobile,
          }"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
            v-vue-aos.once="{animationClass:'fadeInUp animated',animationstart:onAnimationstart}"
          >
            <div
              style="color:#ed7989; font-size: 25px; font-weight: bold;"
            >
              {{ item.v }}
<!--              <count-to-->
<!--                ref="count"-->
<!--                :start-val="0"-->
<!--                :end-val="parseInt(item.v)"-->
<!--                :duration="2000"-->
<!--                :autoplay="false"-->
<!--              />-->
<!--              <span v-if="i === 2" style="font-size: 20px;">W+</span>-->
            </div>
            <div
              class="mt10"
              :class="isMobile ? 'f14' : 'f20'"
            >
              {{ item.t }}
            </div>
          </div>
        </div>

<!--        <div style="display: block;"-->
<!--             data-aos="flip-left"-->
<!--             data-aos-easing="ease-out-cubic"-->
<!--             data-aos-duration="2000"-->
<!--             v-vue-aos.once="{animationClass:'fadeInUp animated',animationstart:onAnimationstart}">-->
<!--          <div style="margin-top: 10px; color: #aaa;"-->
<!--               :style="isMobile ? 'font-size: 12px; padding:0 15px 15px 15px; ' : 'font-size: 13px; padding-bottom:15px;'"-->
<!--          >{{ page[4].tips }}</div>-->
<!--        </div>-->
      </div>

    </div>

    <div
      class="tc pb50 bg-fff"
    >
      <div
        class="relative z1"
        :style="isMobile ? 'font-size:24px;padding: 30px 0 0px 0;' : 'font-size:40px;padding: 80px 0 10px 0;'"
        data-aos="fade-up"
        v-vue-aos.once="{animationClass:'fadeInUp animated'}"
      >
        {{ page[5].t1 }}
      </div>
      <div
        class="dib relative z1"
        style="width: 30px;height: 2px;background: #ed7989;"
      />
      <div
        class="flex-center"
        :class="isMobile ? 'flex-wrap' : ''"
        :style="isMobile ? '' : 'margin-top:150px;padding-bottom:100px;'"
      >
        <div
          v-for="(item,i) in page[5].t2"
          :key="item.t"
          :style="{
            margin: isMobile ? '50px 0' : '0 95px',
            width: isMobile ? '50%' : '190px',
            padding: isMobile ? '0 25px' : ''
          }"
          data-aos="fade-up"
          data-aos-easing="ease-out-cubic"
          data-aos-anchor-placement="top-center"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        >
          <img
            :src="[site.icon.n1,site.icon.n2,site.icon.n3,site.icon.n4][i]"
            style="width:40px"
            alt=""
          >
          <div
            class="f16 b mtb15"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
            v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          >
            {{ item.t }}
          </div>
          <div
            class="f14"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
            v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          >
            {{ item.d }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      page: []
    }
  },

  async created () {
    const { data } = await this.fetch('/index.json')
    this.page = data
  },

  methods: {
    // onSwiper(swiper) {
    //   console.log(swiper)
    // },
    onSlideChange() {
      // console.log('slide change')
    },
    onAnimationstart() {
      this.$refs.count.forEach(vm => vm.start())
    }
  }
}
</script>
